p {
  font-family: 'Diphylleia', serif;
  color: #a7a9ac;
  font-weight: bold;
}

.errorMessage {
  font-family: 'Diphylleia', serif;
  color: red;
  font-size: small
}

.required:before {
  content:" *";
  color: red;
}


h1,h2,h3{
  font-family: 'Diphylleia', serif;
  color: #a7a9ac;
  font-weight: bolder;
}

.title-name{
  font-size: large;
  color: white;
}

.row-content {
  padding: 5px 0;
  min-height: 60px;
}


.responsive {
  max-width: 100%;
  height: auto;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 40%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left:0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 70%;
  position: absolute;
  margin: auto;
} 

.center{
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 30%;
}

.site-header {
  background-color: #552448;
  padding: 1em 0;
  
}

.site-footer {
  background-color: #2a2223;
  padding: 20px 0;
 
}

.page-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: 300ms ease-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: 300ms ease-in;
}
